// ELS CANVIS O MILLORES FETS EN AQUEST FITXER CAL TRASLLADAR-LOS AL PLAYER.JSX DE 3CATINFO

import { getImatge } from '@portal-internet/components';
import { setItem } from '@portal-internet/core';
import clsx from 'clsx';
import Icona from 'components/3cat/ui/Icona/Icona';
import useRmpLoginStateInterop from 'hooks/useRmpLoginStateInterop';
import useStartOverHelper from 'hooks/useStartOverHelper';
import { useUser } from 'hooks/useUser';
import { useViewport } from 'hooks/useViewport';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { forwardRef, useEffect, useContext, useImperativeHandle, useRef, useState } from 'react';
import { render } from 'react-dom';
import { getCropByViewport } from 'utils/imatgesHelper';
import { setGlobalEvar } from '../Audiencies/audiencies-datalayer';
import BotonsAccesibilitat from './Accesibilitat/BotonsAccesibilitat';
import FullScreen from './FullScreen';
import VideoBase from './VideoBase';
import styles from './player.module.scss';
import { HistoryContext } from 'context/3cat/HistoryProvider';
import { constructMediaUrl, getParametresExtra } from './playerHelpers';

const _isDirecte = (id) => {
    return [
        'cr',
        'ci',
        'cia',
        'cm',
        'ic',
        '324',
        'sx3',
        'tv3',
        'oca1',
        'pucoca1',
        'oca2',
        'pucoca2',
        'oca3',
        'pucoca3',
        'ocr1',
        'esport3',
        '33',
        'c33',
        'tv3cat',
        'tvc',
        'tvi',
        'fc1',
        'fc2',
        'fc3',
        'pucfc1',
        'pucfc2',
        'pucfc3',
        'bondiatv'
    ].includes(id);
};
const Player = forwardRef(function Player(props, publicRef) {
    const {
        routerQuery,
        isPopup,
        tipus,
        versio,
        parametresExtraWs,
        playerVideoTagId,
        mostraRelacionats,
        mostraCasting,
        autofullscreen,
        autoPlay,
        isWebviewActive,
        ocultaInfo,
        updateInfo,
        showBotoAd,
        adPlaying,
        updateAudioDesc,
        desactivaAudioDesc,
        embed,
        estils,
        playerError,
        state,
        endPubDate,
        imatges,
        pushMediaModel,
        onNextVideo,
        variantsAD
    } = props;

    const { setClickOrigin, resetClickOriginData } = useContext(HistoryContext);

    let { hashStartOverParams } = props;

    let id = props?.id || routerQuery?.id;
    let directe = props?.id ? props?.directe : _isDirecte(id);

    const router = useRouter();

    const { ...userData } = useUser();
    const { setRmpLoginState } = useRmpLoginStateInterop();
    useEffect(() => {
        // Actualitza l'estat del LOGIN per al marcatge d'audiències
        // realitzat per Jitpub\Modul\RmpBundle (TVCPlayerCore)
        setRmpLoginState(userData);
    }, [userData, setRmpLoginState]);

    const [isActive, setIsActive] = useState(true);
    const [isGeoBlocked, setIsGeoBlocked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [modelFetched, setModelFetched] = useState(false);
    const [mediaModel, setMediaModel] = useState(null);
    const [idint, setIdint] = useState(null);
    const [globalController, setGlobalController] = useState(null);
    const [titol, setTitol] = useState(null);
    const [durada, setDurada] = useState(null);
    const [edat, setEdat] = useState(null);
    const [aud, setAud] = useState(null);
    const [next, setNext] = useState(false);
    const [nomProg, setNomProg] = useState(null);
    const [controlsAccessibles] = useState(true);
    const [textAudPlayer, setTextAudPlayer] = useState('');
    const [inactiveButtons, setInactiveButtons] = useState(clsx({ [styles.inactiveButtons]: directe }));
    const [inactiveStartOverButton, setInactiveStartOverButton] = useState(clsx({ [styles.inactiveStartOverButton]: false }));
    const [startOverPlayer, setStartOverPlayer] = useState(null);
    const [reloadStartOver, setReloadStartOver] = useState(null);

    useImperativeHandle(publicRef, () => ({
        play(time = 0) {
            globalController?.sendCommand('currentTime', time);
            globalController?.sendCommand('play');
        },
        getCurrentAudioTrack() {
            return getCurrentAudioTrack(globalController);
        },
        activaStartOver(activa) {
            let data = {
                actiu: activa
            };
            handleStartOver(data);
        },
        handleAudioDesc() {
            changeAudioDescPlayer();
        }
    }));

    const { app, frontal, agrupacio, ranking } = router.query;

    const params = {
        profile: app,
        frontal: frontal,
        agrupacio: agrupacio,
        ranking: ranking
    };
    const { viewport } = useViewport();

    const dataParametresExtraWs = getParametresExtra(parametresExtraWs, params);

    const dataAutoFullScreen = router.query['autofullscreen'] === 'yes' || autofullscreen;
    const dataMostraRelacionats = router.query['mostra_relacionats'] === 'yes' || mostraRelacionats;
    const dataAudiodesc = __isEmpty(variantsAD) ? null : router.query['ad'] === 'yes' || router.query['ad'] === 'true' || null;
    const [audioDescPlayer, setAudioDescPlayer] = useState(dataAudiodesc);
    const dataAutoPlay =
        router.query['autoplay'] === 'false'
            ? false
            : router.query['autoplay'] === 'yes' || router.query['autoplay'] === 'true' || autoPlay;
    const dataDelayed = router.query['temps_inici'] ? router.query['temps_inici'] : '0';

    const mediaws = 'https://api-media.3cat.cat/pvideo/media.jsp';
    const [offset] = useState(0);

    const GEO_ZONE_DICTIONARY_CHANNELS = {
        CATALUNYA: 'tv3',
        ESPANYA: 'tvc',
        TOTS: 'tvi'
    };

    const temps_inici = router.query['temps_inici'] !== undefined ? decodeURIComponent(router.query['temps_inici']) : '';

    const stylesRmplayer = clsx({
        'M-rmp': true,
        'C-audio': tipus === 'audio' && estils !== 'noticia' && !embed,
        'C-standaloneAudio': (estils === 'noticia' || embed) && tipus === 'audio',
        'C-directe': directe,
        'C-popup': isPopup,
        [styles.rmplayer]: true,
        [styles.rmplayerAudio]: tipus === 'audio',
        [styles[estils]]: estils,
        [styles.embed]: embed,
        [styles.popup]: isPopup,
        [styles.webview]: isWebviewActive,
        [styles.rmpDirecte]: directe
    });

    const stylesErrorText = clsx({
        [styles.errorText]: !isGeoBlocked,
        [styles.geoBlocked]: isGeoBlocked
    });

    const nextVideo = (event) => {
        setNext(true);
        setAudioDescPlayer(false);

        if (desactivaAudioDesc !== undefined) {
            desactivaAudioDesc();
        }
        if (isWebviewActive) {
            setIdint(event.detail.idint);
        } else {
            if (isWebviewActive) {
                setIdint(event.detail.idint);
            } else {
                router.replace({
                    pathname: '/3cat/' + event.detail.slug + '/video/' + event.detail.idint + '/',
                    query: null
                });
            }
        }
    };

    const getCurrentAudioTrack = (controller) => {
        try {
            return controller.sendCommand('getCurrentAudioTrack');
        } catch (error) {
            return 0;
        }
    };

    const objParams = useRef({
        tipus,
        dataParametresExtraWs,
        directe,
        mediaws,
        versio,
        id,
        offset
    });
    const hasAssignedOffset = useRef(false);
    const { getHashParamsFromUrl } = useStartOverHelper();

    if (hashStartOverParams == null) {
        hashStartOverParams = getHashParamsFromUrl(router.asPath.split('#')[1]);
        if (hashStartOverParams?.hashStartOverParams) {
            hashStartOverParams = hashStartOverParams?.hashStartOverParams;
        }
    }

    const isStartOver = !!hashStartOverParams?.start || undefined;

    const accessKey = (command) => {
        if (command === 'play') {
            globalController?.sendCommand('paused')
                ? globalController?.sendCommand('play')
                : globalController?.sendCommand('pause');
        }
        if (command === 'volumeUp') {
            globalController?.sendCommand('volume', globalController?.sendCommand('volume') + 0.2);
        }
        if (command === 'volumeDown') {
            const targetVolume =
                globalController?.sendCommand('volume') - 0.2 === 0 ? -1 : globalController?.sendCommand('volume') - 0.2;
            globalController?.sendCommand('volume', targetVolume);
        }
        if (command === 'mute') {
            globalController?.sendCommand('mute');
        }
    };

    useEffect(() => {
        if (embed && state === 'DESP') {
            const msg = tipus === 'audio' ? 'Aquest àudio està despublicat' : 'Aquest vídeo està despublicat';
            setErrorMessage(msg);
            setIsActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, endPubDate]);

    useEffect(() => {
        objParams.current = {
            tipus,
            dataParametresExtraWs,
            directe,
            mediaws,
            versio,
            id,
            offset
        };

        if (!isStartOver) {
            hasAssignedOffset.current = false;
        }

        if (isStartOver && hashStartOverParams?.desplacament && !hasAssignedOffset.current) {
            //tot el useEffect es per setejar el valor inicial per fer la crida a la media.jsp com desplacament
            objParams.current.offset = hashStartOverParams?.desplacament;
            hasAssignedOffset.current = true;
        }
    }, [tipus, dataParametresExtraWs, directe, mediaws, versio, id, offset, isStartOver, hashStartOverParams?.desplacament]);

    const setupPlayer = (id) => {
        setNext(false);
        let model, paused, relatedOpen, imatgeKeyframe, presentador;

        if (directe) {
            if (tipus === 'audio') {
                model = new window.TVCEndPoints.AudioLiveModel({ idint: id });
                objParams.current.urlBase = window.URLS.AUDIOINFOLIVEPERIDJSON;
            } else {
                model = new window.TVCEndPoints.VideoLiveModel({ idint: id });
                objParams.current.urlBase = window.URLS.VIDEOINFOLIVEPERIDJSON;
            }
            /*-overwrite TVCPlayerCore methods for Startover, etc... -*/
            model.url = () => {
                let url = constructMediaUrl(objParams.current);
                if (isStartOver) {
                    const urlModif = new URL(url);
                    urlModif.searchParams.set('so_id', hashStartOverParams.so_id);
                    urlModif.searchParams.set('start', hashStartOverParams.start);
                    //desplacament va amb el offset dels objParams
                    url = urlModif.href;
                }
                return url;
            };
            model.parse = (data) => {
                imatgeKeyframe = data?.keyframes !== undefined ? data?.keyframes[0]?.url : undefined;
                presentador = data?.informacio?.arafem?.presentador;
                model.update(data);
                clearTimeout(model.nextUpdateTimeout);
                model.nextUpdateTimeout = setTimeout(() => {
                    model.fetch({ success, error });
                }, model.getNextUpdateTime());
                return model;
            };
        } else {
            //VOD
            if (tipus === 'audio') {
                model = new window.TVCEndPoints.AudioModel({ idint: id });
                objParams.current.urlBase = window?.URLS?.AUDIOINFOPERIDJSON;
            } else {
                model = new window.TVCEndPoints.MediaModel({ idint: id });
                objParams.current.urlBase = window?.URLS?.VIDEOINFOPERIDJSON;
            }
            objParams.current.id = id;
            model.url = constructMediaUrl(objParams.current);
        }

        const playerController = new window.TVCPlayerCore.PlayerController(playerVideoTagId, model, true);
        setGlobalController(playerController);

        const openPopup = () => {
            let currentTime = playerController?.sendCommand('currentTime') / 1000.0,
                playing = playerController?.sendCommand('paused') ? 'false' : 'true',
                slug = router?.query?.slug || 'audio';
            if (directe) {
                currentTime = 0;
                window.open(
                    '/3cat/directes/' + router.query.canal + '/popup/?' + 'autoplay=' + playing + '&temps_inici=' + currentTime,
                    '',
                    'width=480, height=320, titlebar=0, resizable=0, scrollbars=0, location=0'
                );
            } else {
                window.open(
                    '/3cat/' + slug + '/audio/' + id + '/popup/?' + 'autoplay=' + playing + '&temps_inici=' + currentTime,
                    '',
                    'width=480, height=320, titlebar=0, resizable=0, scrollbars=0, location=0'
                );
            }
        };

        playerController.on('related-playlist-click', () => {
            resetClickOriginData();
            setClickOrigin('name', 'Relacionats Player');
        });
        playerController.on('changevideo', (ev) => {});
        playerController.on('loadedmetadata', (ev) => {
            if (showBotoAd) showBotoAd(true);
            if (dataAudiodesc && audioDescPlayer) {
                if (updateAudioDesc !== undefined) {
                    updateAudioDesc();
                }
            }
        });
        playerController.on('openPopup', openPopup);
        window.addEventListener('openPopup', openPopup);

        playerController.on('sas_adstart', () => {
            adPlaying(true);
        });
        playerController.on('sas_adend', () => {
            adPlaying(false);
        });
        playerController.on('sas_adcreativitybegin', () => {
            adPlaying(true);
        });
        playerController.on('sas_adcreativityend', () => {
            adPlaying(false);
        });
        playerController.on('playlistend', (ev) => {
            if (onNextVideo) onNextVideo({ currentVideo: id });
        });
        playerController.on('playliststart', () => {});
        playerController.on('playlistready', (ev) => {
            if (isWebviewActive && AppPlayerPlugin) {
                let player = $('#' + playerVideoTagId).parents('.tvcplayer');
                let data = ev?.params !== undefined ? ev?.params : ev;
                AppPlayerPlugin(player, playerController, data);
            }
        });
        playerController.on('error', () => {});
        playerController.on('timeupdate', (ev) => {
            setItem({
                id: id,
                tipology: tipus === 'audio' ? 'WCR_AUDIO_MM' : 'DTY_VIDEO_MM',
                tc: Math.round(ev.params.ms / 1000) //seconds
            });
        });
        playerController.on('play', () => {
            if (hashStartOverParams && hashStartOverParams.audio_index !== undefined) {
                let currentAudioTrack = getCurrentAudioTrack(playerController);
                if (currentAudioTrack !== Number(hashStartOverParams.audio_index)) {
                    playerController.sendCommand('setCurrentAudioTrack', Number(hashStartOverParams.audio_index));
                }
            }
            if (ocultaInfo) {
                ocultaInfo(true);
            }
            if (directe) {
                let currentTime = Math.ceil(playerController.sendCommand('getCurrentTimeSec'));
                if (currentTime < -20) {
                    setInactiveButtons(clsx({ [styles.inactiveButtons]: false }));
                }
            }
        });
        playerController.on('pause', () => {
            if (!relatedOpen && ocultaInfo) {
                ocultaInfo(false);
            }
            if (directe) {
                setInactiveButtons(clsx({ [styles.inactiveButtons]: false }));
            }
        });
        playerController.on('related-playlist-show', () => {
            paused = playerController.sendCommand('paused');
            relatedOpen = true;
            if (ocultaInfo) {
                ocultaInfo(true);
            }
        });
        playerController.on('related-playlist-close', () => {
            relatedOpen = false;
            if (paused && ocultaInfo) {
                ocultaInfo(false);
            }
        });
        playerController.on('seeked', () => {
            if (directe) {
                //startover té directe true.
                const currentTime = Math.ceil(playerController.sendCommand('getCurrentTimeSec') / 60);
                if (currentTime < 0) {
                    setInactiveButtons(clsx({ [styles.inactiveButtons]: false }));
                } else {
                    setInactiveButtons(clsx({ [styles.inactiveButtons]: true }));
                }
                objParams.current.offset = currentTime * -1;
                model.fetch({ success, error });
            }
        });

        const success = (response) => {
            setModelFetched(true);
            setMediaModel(response);
            if (pushMediaModel) pushMediaModel(response);
            if (directe) {
                let titol = __get(response, 'attributes.informacio.attributes.arafem.attributes.titol') || '',
                    titol_complet = __get(response, 'attributes.informacio.attributes.arafem.attributes.titol_complet') || '',
                    programa = __get(response, 'attributes.informacio.attributes.arafem.attributes.programa') || '',
                    codiEtic =
                        __get(response, 'attributes.informacio.attributes.arafem.attributes.codi_etic.attributes.text') || '',
                    entradeta = __get(response, 'attributes.informacio.attributes.arafem.attributes.descripcio') || '',
                    despresPrograma = __get(response, 'attributes.informacio.attributes.despresfem.programa') || '',
                    despresTitol = __get(response, 'attributes.informacio.attributes.despresfem.titol') || '',
                    despresHora = __get(response, 'attributes.informacio.attributes.despresfem.data_emissio.utc') || '',
                    startOverParams = __get(response, 'attributes.startover.params') || false;

                if (document.querySelector('#' + playerVideoTagId + ' .fullscreenrow')) {
                    renderFullscreen(titol, programa, codiEtic);
                }
                if (startOverParams && !isStartOver) {
                    setInactiveStartOverButton(clsx({ [styles.inactiveStartOverButton]: false }));
                    setStartOverPlayer(startOverParams);
                } else {
                    setInactiveStartOverButton(clsx({ [styles.inactiveStartOverButton]: true }));
                    setInactiveButtons(clsx({ [styles.inactiveButtons]: false }));
                    setStartOverPlayer(false);
                }
                if (isStartOver) {
                    setInactiveStartOverButton(clsx({ [styles.inactiveStartOverButton]: false }));
                }
                if (!__isUndefined(updateInfo)) {
                    let imatgeKeyCrop = imatgeKeyframe !== undefined ? cropImatge(imatgeKeyframe) : '';
                    let offset = objParams?.current?.offset || 0;
                    const dades = {
                        titol,
                        titol_complet,
                        entradeta,
                        despresPrograma,
                        despresTitol,
                        despresHora,
                        presentador,
                        imatgeKeyCrop,
                        offset,
                        ...(startOverParams && { startOverParams })
                    };
                    updateInfo(dades);
                }
            }
        };
        const error = () => {
            console.warn('error on media.jsp response');
        };
        model.fetch({ success, error });
        return () => {
            playerController.off('changevideo', () => {});
            playerController.off('loadedmetadata', () => {});
            playerController.off('sas_adstart', () => {});
            playerController.off('sas_adend', () => {});
            playerController.off('sas_adcreativitybegin', () => {});
            playerController.off('sas_adcreativityend', () => {});
            playerController.off('playlistend', () => {});
            playerController.off('playliststart', () => {});
            playerController.off('playlistready', () => {});
            playerController.off('error', () => {});
            playerController.off('timeupdate', () => {});
            playerController.off('play', () => {});
            playerController.off('pause', () => {});
            playerController.off('seeked', () => {});
            playerController.off('related-playlist-show', () => {});
            playerController.off('related-playlist-close', () => {});
            playerController.off('ready', () => {});
            playerController.off('openPopup', () => {});
            window.removeEventListener('openPopup', openPopup);
            clearTimeout(model.nextUpdateTimeout);
            playerController.destroy();
        };
    };

    const cropImatge = (imatge) => {
        if (__isUndefined(imatge) || !imatge.includes('img.ccma.cat') || !imatge.includes('img.3cat.cat')) return imatge;
        let crop = '';

        switch (viewport) {
            case 'VP_MOBILE':
            case 'VP_SMALL':
                crop = '670';
                break;
            case 'VP_REGULAR':
                crop = '1014';
                break;
            case 'VP_LARGE':
            default:
                crop = '1920';
                break;
        }

        const splitimatge = imatge?.split('.');
        const extensio = `.${splitimatge[splitimatge.length - 1]?.trim()}`;
        return imatge.replace(extensio, `_${crop}${extensio}`);
    };

    const changeAudioDescPlayer = () => {
        setAudioDescPlayer(!audioDescPlayer);
    };

    const renderFullscreen = (titolFS, programaFS, codiEtic) => {
        let fsWraper = document.querySelector('#' + playerVideoTagId + ' .fullscreenrow');
        render(
            <FullScreen
                titol={titol || titolFS}
                nomProg={nomProg || programaFS}
                changeAudioDesc={changeAudioDescPlayer}
                textAud={textAudPlayer}
                edat={edat || codiEtic}
                aud={aud}
            />,
            fsWraper
        );
    };

    useEffect(() => {
        if (isWebviewActive) {
            window.DISABLEAUTOHIT = true;
        }
        if (mediaModel) {
            if (directe) {
                setIsActive(__get(mediaModel, 'attributes.informacio.attributes.arafem.attributes.durada.text') !== '');
                setErrorMessage(__get(mediaModel, 'attributes.informacio.attributes.arafem.attributes.drets.text'));
                setBackgroundImage(__get(mediaModel, 'attributes.imatges.models[0].attributes.url'));
                setTitol(__get(mediaModel, 'attributes.informacio.attributes.arafem.attributes.titol'));
                setNomProg(__get(mediaModel, 'attributes.informacio.attributes.arafem.attributes.programa'));
                setEdat(__get(mediaModel, 'attributes.informacio.attributes.arafem.attributes.codi_etic.attributes.text'));
            } else {
                setTitol(__get(mediaModel, 'attributes.informacio.attributes.titol'));
                setDurada(__get(mediaModel, 'attributes.informacio.attributes.durada.attributes.milisegons'));
                setNomProg(__get(mediaModel, 'attributes.informacio.attributes.programa'));
                setEdat(__get(mediaModel, 'attributes.informacio.attributes.codi_etic.attributes.id'));
            }

            let videoTag = $('#' + playerVideoTagId).parents('.tvcplayer');
            let variants = __get(mediaModel, 'attributes.variants');
            setAud(variants !== undefined && variants?.length > 0);
            let imatgePlayer =
                __get(mediaModel, 'attributes.keyframes.models[0].attributes.url') ||
                __get(mediaModel, 'attributes.imatges.models[0].attributes.url');
            videoTag.data('imatge_poster', cropImatge(imatgePlayer));
            videoTag.data('subtitles', __get(mediaModel, 'attributes.subtitols'));
            videoTag.data('sprites', __get(mediaModel, 'attributes.sprites'));
            videoTag.data('durada', __get(mediaModel, 'attributes.informacio.attributes.durada.attributes.milisegons'));
            if (tipus === 'video') {
                videoTag.data('title', __get(mediaModel, 'attributes.informacio.attributes.titol'));
            }
            let geoBlocked = __get(mediaModel, 'attributes.geoblocked');

            if (geoBlocked) {
                setIsGeoBlocked(geoBlocked);
                setErrorMessage('Aquest contingut no està disponible en la teva ubicació');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaModel]);

    useEffect(() => {
        if (!__isEmpty(playerError)) {
            errorMessage != '' ? playerError(true) : playerError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage]);

    useEffect(() => {
        let removeSetupPlayer,
            mediaId = idint || id;
        if (window?.setGlobalEvar === undefined) {
            window.setGlobalEvar = setGlobalEvar;
        }
        if (mediaId === 'tv3' && window.geoZoneHandler) {
            window.geoZoneHandler.getGeoZone((geo) => {
                if (removeSetupPlayer) {
                    removeSetupPlayer();
                }
                removeSetupPlayer = setupPlayer(GEO_ZONE_DICTIONARY_CHANNELS[geo]);
                window.addEventListener('changeVideo', nextVideo);
            });
        } else {
            if (removeSetupPlayer) {
                removeSetupPlayer();
            }
            removeSetupPlayer = setupPlayer(mediaId);
            window.addEventListener('changeVideo', nextVideo);
        }

        return () => {
            window.removeEventListener('changeVideo', nextVideo);
            removeSetupPlayer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, idint, reloadStartOver]);

    const handleStartOver = (ev) => {
        let data = ev.params || ev.detail || ev;
        let actiu = data?.actiu || false;
        let concat = '#';
        let audioIndex = getCurrentAudioTrack(globalController);
        let urlSplited = router.asPath?.split('#');
        let url = urlSplited[0];
        let hash = urlSplited[1] !== undefined ? '#' + urlSplited[1] : '#';
        hash = getCleanHash(hash);
        if (hash !== '#') {
            url = url + hash;
            concat = '&';
        }
        if (actiu) {
            url = url + concat + startOverPlayer;
        }
        if (audioIndex > 0) {
            let audioParam = '#audioIndex=';
            if (url.includes('#')) {
                audioParam = '&audioIndex=';
            }
            url = url + audioParam + audioIndex;
        }
        router.push(url);
        setReloadStartOver(actiu);
    };

    const getCleanHash = (hash) => {
        let cleanHash = hash;
        if (cleanHash.includes('#so_id')) {
            cleanHash = cleanHash.split('so_id')[0];
        }
        if (cleanHash.includes('&so_id')) {
            cleanHash = cleanHash.split('&so_id')[0];
        }
        if (cleanHash.includes('#audioIndex')) {
            cleanHash = cleanHash.split('audioIndex')[0];
        }
        if (cleanHash.includes('&audioIndex')) {
            cleanHash = cleanHash.split('&audioIndex')[0];
        }
        return cleanHash;
    };

    useEffect(() => {
        if (startOverPlayer !== null) {
            window.removeEventListener('startOver', handleStartOver);
            window.addEventListener('startOver', handleStartOver);
            if (globalController) {
                globalController.off('startOver', handleStartOver);
                globalController.on('startOver', handleStartOver);
            }
        }
        return () => {
            if (globalController) {
                globalController.off('startOver', handleStartOver);
            }
            window.removeEventListener('startOver', handleStartOver);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startOverPlayer]);

    useEffect(() => {
        let textAud = audioDescPlayer ? 'Desactiva' : 'Activa';
        setTextAudPlayer(textAud);

        if (globalController && !next) {
            let aud = audioDescPlayer ? 'activaAud' : 'desactivaAud';
            globalController?.sendCommand(aud);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioDescPlayer]);

    useEffect(() => {
        const updateFullScreen = () => {
            renderFullscreen();
        };
        if (globalController && tipus === 'video') {
            globalController.on('loadedmetadata', updateFullScreen);
            globalController.on('ready', updateFullScreen);
        }
        return () => {
            if (globalController) {
                globalController.off('loadedmetadata', updateFullScreen);
                globalController.off('ready', updateFullScreen);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titol, nomProg, edat, aud, textAudPlayer, audioDescPlayer]);

    const playerBackground = getImatge(imatges, 'web_player_poster_background', null, getCropByViewport(viewport), null);
    const stylesBackground = playerBackground
        ? {
              backgroundImage: 'url(' + playerBackground + ')',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '100%'
          }
        : {};

    return (
        <div style={stylesBackground}>
            <Script src='https://statics.3cat.cat/js/vendor/notification.center.js' />
            <Script src='https://statics.3cat.cat/js/vendor/springstreams.min.js' />
            {modelFetched && (
                <div className={`${stylesRmplayer} ${inactiveButtons} ${inactiveStartOverButton}`} data-testid={`player-${id}`}>
                    <BotonsAccesibilitat key={id} controlsAccessibles={controlsAccessibles} accessKey={accessKey} />
                    <div
                        className='tvcplayer videoplayer'
                        data-audio-route-path='/catradio/alacarta/{slug2}/{slug}/audio/{id}/'
                        data-autoadjustheight='1'
                        data-autofullscreen={dataAutoFullScreen ? '1' : '0'}
                        data-autoplay={dataAutoPlay ? '1' : '0'}
                        data-blacklist='https://dinamics.3cat.cat/public/player/blacklist.json'
                        data-buttons='{}'
                        data-controls-accessibles={controlsAccessibles ? '1' : '0'}
                        data-delayed={dataDelayed || '0'}
                        data-durada={durada}
                        data-extrawsparams={dataParametresExtraWs}
                        data-idint={id}
                        data-jwskin='ccma'
                        data-skin='ccma'
                        data-jwskinaudio='ccma'
                        data-live={directe ? '1' : '0'}
                        data-mostra_event_fs=''
                        data-mostra_relacionats={dataMostraRelacionats ? '1' : '0'}
                        data-mostracasting={mostraCasting ? '1' : '0'}
                        data-popupurl='https://www.3cat.cat/audio/popup/'
                        data-producte=''
                        data-publivastios=''
                        data-img-src=''
                        data-type={tipus}
                        data-versio='vast'
                        data-video-route-path='/tv3/alacarta/{slug2}/{slug}/video/{id}/'
                        data-vr=''
                        data-initaud={dataAudiodesc}
                    >
                        {isActive && !isGeoBlocked ? (
                            <>
                                <VideoBase playerVideoTagId={playerVideoTagId} />
                            </>
                        ) : (
                            <div
                                className={styles.errorContent}
                                style={{ backgroundImage: `url(${backgroundImage})` }}
                                data-testid='player-error'
                            >
                                <div className={styles.errorLayer}>
                                    <div className={styles.errorMsg}>
                                        <div className={stylesErrorText}>
                                            {!isGeoBlocked && <Icona icona='avis' alt='avis' mida={28} />}
                                            {isGeoBlocked && (
                                                <Icona className={styles.icoGeoBlocked} icona='mon' alt='mon' mida={94} />
                                            )}
                                            <div className={styles.text}>{errorMessage}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

Player.defaultProps = {
    tipus: 'video',
    playerVideoTagId: 'view1',
    versio: 'vast',
    mostraRelacionats: true,
    autofullscreen: false,
    autoPlay: false,
    mostraCasting: true,
    parametresExtraWs: '',
    isWebviewActive: false,
    isPopup: false,
    embed: false,
    className: null
};

export default Player;
